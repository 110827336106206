import StatusCode from 'status-code-enum';

export type ValidationErrorResponse = {
  code: number;
  field: string;
  message: string;
}[];

type ErrorType = 'UNKNOWN' | 'NOT_FOUND' | 'VALIDATION';

export type ValidationError = {
  code: number;
  field: string;
  message: string;
}[];

export type ResponseError = {
  type: ErrorType;
  error: Error | ValidationError;
};

export const isNotFoundError = (status: number) =>
  status === StatusCode.ClientErrorNotFound;
export const isValidationError = (status: number) =>
  status === StatusCode.ClientErrorUnprocessableEntity;

export const getStatusCode = (
  code: string | undefined,
): StatusCode | 'default' => {
  if (!code) return 'default';
  const statusCode = Number.parseInt(code);
  if (Number.isNaN(statusCode)) return 'default';
  return statusCode as StatusCode;
};
