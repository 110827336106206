import getConfig from 'next/config';

const {
  publicRuntimeConfig: { CDN_STATIC_ASSETS },
} = getConfig();

export const swapTestDomains = (url: string, environment: string) => {
  if (environment === 'dev' && url.includes('donedealtest.com')) {
    return url.replace('.com', '.local');
  }
  return url;
};

export const getUrlAssetImageLogo = (imageName: string) => {
  return `${CDN_STATIC_ASSETS}${`/images/logos/${imageName}.svg`}`;
};
