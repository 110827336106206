import type { AxiosResponse } from 'axios';

import type { TClearPendingPaymentResponse } from 'api/types/frontendApiTypes';
import { frontendServerGateway } from 'api/api-utils/commonApiUtils';

const clearPendingPayment = async (): Promise<
  AxiosResponse<TClearPendingPaymentResponse>
> => {
  return await frontendServerGateway.get('/payment/cancel');
};

export const frontendApi = {
  clearPendingPayment,
};
