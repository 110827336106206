import { Base } from 'domains/Base';
import { Ad } from 'domains/Ad';
import { OrderPayment } from 'domains/Payment';

export enum ORDER_STATUS {
  PUBLISH = 'PUBLISHED',
  IN_REVIEW = 'IN_REVIEW',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  PAYMENT_REQUIRED = 'PAYMENT_REQUIRED',
  VEHICLE_REPORT = 'VEHICLE_REPORT',
  BUMP = 'BUMP',
  SUPERBOOSTER = 'SUPERBOOSTER',
}

export enum ORDER_ERROR_STATUS {
  FORBIDDEN = 'FORBIDDEN',
  UNDEFINED_ORDER = 'UNDEFINED_ORDER',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  UNKNOWN = 'UNKNOWN',
}

export enum BUNDLE {
  BASIC = 'BASIC',
  AUTOBUMP = 'AUTOBUMP',
  SPOTLIGHT = 'SPOTLIGHT',
}

export type TOrderAd = Ad | null;
export type TOrderPayment = OrderPayment | null;
export type TOrderStatus = ORDER_STATUS | null;
export type TOrderBundle = BUNDLE | null;
export type TOrderTracking = OrderTracking | null;

export interface Order extends Base {
  ad: TOrderAd;
  payment: TOrderPayment;
  status: TOrderStatus;
  bundle: TOrderBundle;
  formToken: string;
  crossPlatform?: boolean;
  historyCheck?: {
    email: string;
    paymentMethod: string;
    orderNo: number;
    report: string;
    price: string;
  };
  tracking: TOrderTracking;
}

export interface GetOrder extends Base {}

export interface PayPalRedirect {
  url: string;
}

export interface ConfirmPhone {
  id: string;
}

/** ORDER TRACKING - FIND A BETTER PLACE FOR THESE TYPES? */
export type EventType = 'ad_is_placed' | 'purchase';

export type TransactionProduct = {
  sku: number;
  name: string;
  price: number;
  quantity: number;
};

export interface OrderTracking {
  adId: [string] | null;
  adSection: [string] | null;
  price: [string];
  deliveryAvailable: [boolean];
  dealerId: [string] | null;
  sellerId: [string] | null;
  sellerType: [string];
  make: [string] | null;
  model: [string] | null;
  year: [string] | null;
  paymentMethod: [string] | null;
  county: [string] | null;
  transactionId: [string] | null;
  transactionTotal: number | null;
  transactionProducts: TransactionProduct[] | null;
  bundle: [string] | null;
  bundlePrice: [number] | null;
}

export type Upsell = {
  orderID: string;
};
