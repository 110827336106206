import { donedealGateway } from 'api/api-utils/commonApiUtils';
import type { AxiosResponse } from 'axios';
import { IncomingHttpHeaders } from 'http';
import type {
  HistoryCheckPayload,
  HistoryCheckResponse,
} from 'api/types/historyCheckApiTypes';

async function getHistoryCheck(
  payload: HistoryCheckPayload,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<HistoryCheckResponse>> {
  return await donedealGateway.post('/ddapi/v1/orders/history-check', payload, {
    headers,
  });
}

export const historyCheckApi = { getHistoryCheck };
